var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"callsListWrapper"},[_c('div',{staticClass:"SearchbarWrapper"},[_c('div',{staticClass:"searchbar"},[_c('Search',{attrs:{"placeholder":_vm.placeholder,"isAdvanceSearch":true,"filterConfig":_vm.searchConfig},on:{"queryString":_vm.queryString,"filterObject":_vm.advanceSearch,"clearFilter":_vm.advanceSearch}})],1),_c('div',{staticClass:"datepicker"},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.datePickerDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.datePickerDate=$event},"update:return-value":function($event){_vm.datePickerDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Sales call range","readonly":"","outlined":""},on:{"click":_vm.dataSelector},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(_vm.datePickerDate),callback:function ($$v) {_vm.datePickerDate=$$v},expression:"datePickerDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.resetDatePicker}},[_vm._v(" Reset ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.cancelDatePicker}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.submitDatePicker}},[_vm._v(" OK ")])],1)],1)],1)]),_c('div',{staticClass:"download-button"},[(_vm.type === 'sales_call' || (_vm.type === 'all_sales_call' && _vm.isAdminOrManagement))?_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.downloadCalls}},[_vm._v(" Download Calls ")]):_vm._e()],1),(_vm.totalCount === 0)?_c('div',{staticClass:"content-error-message"},[_vm._v(" Please add a sales call Entry ")]):_c('div',{staticClass:"info-table"},[_c('v-data-table',{attrs:{"items-per-page":_vm.pageSize,"hide-default-footer":"","headers":_vm.headers,"items":_vm.callsList,"item-key":"_id","fixed-header":true},scopedSlots:_vm._u([{key:"item.date_of_call",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date_of_call, "MMMM Do YYYY dddd"))+" ")]}},{key:"item.company_data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.company_data ? item.company_data.name : "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canUserEdit(item))?[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openInputForm(true, item)}}},[_vm._v("EDIT")]),_c('v-list-item',{on:{"click":function($event){return _vm.deleteCall(item)}}},[_vm._v("DELETE")])],1)],1)]:_vm._e()]}}],null,true)})],1),(_vm.isPaginationRequired)?_c('div',{staticClass:"paginationWrapper text-center"},[_c('v-pagination',{staticClass:"pagination-component",attrs:{"length":Math.ceil(_vm.fetchCount / _vm.pageSize),"total-visible":_vm.paginationTotalVisible},on:{"input":_vm.updatedPageNo},model:{value:(_vm.pageNo),callback:function ($$v) {_vm.pageNo=$$v},expression:"pageNo"}}),_c('div',{staticClass:"page-size-dropdown"},[_c('v-autocomplete',{attrs:{"items":_vm.pageSizeList,"auto-select-first":"","solo":"","dense":""},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1)],1):_vm._e(),(_vm.type == 'sales_call')?[_c('UserForm',{attrs:{"name":_vm.name,"type":_vm.type,"inputConfig":_vm.inputConfig,"keysToWatch":_vm.keysToWatch,"toggleForm":_vm.toggleForm,"formData":_vm.rowToEdit,"isEditMode":_vm.isEditMode},on:{"formOutput":_vm.formOutput,"closeForm":_vm.closeForm}})]:_vm._e(),_c('div',{staticClass:"floating-button"},[(_vm.type == 'sales_call')?_c('v-btn',{attrs:{"color":"primary","dark":"","fab":""},on:{"click":function($event){return _vm.openInputForm()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","fab":""},on:{"click":function($event){return _vm.getData()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh List")])])]],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }